const YourBookingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M18 3.49989V3.49989C19.6569 3.49989 21 4.84303 21 6.49988L21 8.64274C21 8.97686 21 9.14393 20.9234 9.26589C20.8834 9.3295 20.8296 9.38328 20.766 9.42324C20.644 9.49988 20.477 9.49988 20.1429 9.49988L15 9.49988M18 3.49989V3.49989C16.3431 3.49989 15 4.84303 15 6.49988L15 9.49988M18 3.49989L7 3.49989C5.11438 3.49989 4.17157 3.49989 3.58579 4.08567C3 4.67146 3 5.61427 3 7.49989L3 21.4999L6 20.4999L9 21.4999L12 20.4999L15 21.4999L15 9.49988"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
      <path
        d="M7.00049 7.49988L11.0005 7.49988"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.99951 11.5H6.99951"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.00049 15.4999L10.0005 15.4999"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default YourBookingIcon;
