const CopyIcon = (props) => {
  return (
    <svg
      width={props.width || "33"}
      height={props.width || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.200012"
        y="0.5"
        width="32"
        height="32"
        rx="12.8"
        fill={process.env.PRIMARY_LIGHT_COLOR || "#E1EBFF"}
      />
      <path
        d="M19.4001 6.90039H13.0001C10.6437 6.90039 8.73343 8.81064 8.73343 11.1671V19.7004"
        stroke={process.env.PRIMARY_COLOR || "#2A85DD"}
        stroke-width="1.6"
      />
      <path
        d="M12.7334 15.9673C12.7334 14.6977 12.7346 13.8124 12.811 13.1345C12.8856 12.4726 13.0234 12.108 13.2294 11.8396C13.357 11.6732 13.506 11.5242 13.6724 11.3965C13.9408 11.1906 14.3054 11.0528 14.9673 10.9782C15.6453 10.9018 16.5305 10.9006 17.8001 10.9006C19.0697 10.9006 19.9549 10.9018 20.6329 10.9782C21.2947 11.0528 21.6594 11.1906 21.9278 11.3965C22.0942 11.5242 22.2431 11.6732 22.3708 11.8396C22.5768 12.108 22.7145 12.4726 22.7891 13.1345C22.8656 13.8124 22.8667 14.6977 22.8667 15.9673V20.2339C22.8667 21.5035 22.8656 22.3887 22.7891 23.0667C22.7145 23.7286 22.5768 24.0932 22.3708 24.3616C22.2431 24.528 22.0942 24.677 21.9278 24.8046C21.6594 25.0106 21.2947 25.1483 20.6329 25.223C19.9549 25.2994 19.0697 25.3006 17.8001 25.3006C16.5305 25.3006 15.6453 25.2994 14.9673 25.223C14.3054 25.1483 13.9408 25.0106 13.6724 24.8046C13.506 24.677 13.357 24.528 13.2294 24.3616C13.0234 24.0932 12.8856 23.7286 12.811 23.0667C12.7346 22.3887 12.7334 21.5035 12.7334 20.2339V15.9673Z"
        stroke={process.env.PRIMARY_COLOR || "#2A85DD"}
        stroke-width="1.6"
      />
    </svg>
  );
};

export default CopyIcon;
