const PointIcon = (props) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.width || "25"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.002 6.99988C19.002 8.38059 15.8679 9.49988 12.002 9.49988C8.13596 9.49988 5.00195 8.38059 5.00195 6.99988M19.002 6.99988C19.002 5.61917 15.8679 4.49988 12.002 4.49988C8.13596 4.49988 5.00195 5.61917 5.00195 6.99988M19.002 6.99988V18.9999C19.002 20.3806 15.8679 21.4999 12.002 21.4999C8.13596 21.4999 5.00195 20.3806 5.00195 18.9999V6.99988M19.002 10.9999C19.002 12.3806 15.8679 13.4999 12.002 13.4999C8.13596 13.4999 5.00195 12.3806 5.00195 10.9999M19.002 14.9999C19.002 16.3806 15.8679 17.4999 12.002 17.4999C8.13596 17.4999 5.00195 16.3806 5.00195 14.9999"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PointIcon;
