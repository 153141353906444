const ZoneIcon = (props) => {
  return (
    <svg
      width={props.width || "33"}
      height={props.width || "32"}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9201 8L24.9645 9.46667C24.5029 9.81286 23.9415 10 23.3645 10H18.8867C18.09 10 17.3617 10.4501 17.0054 11.1628V11.1628C16.5657 12.0421 16.7938 13.1088 17.5548 13.7314L20.2214 15.9132C22.32 17.6302 23.406 20.2934 23.1066 22.9883L23.08 23.2275C22.9739 24.1826 22.7392 25.1189 22.3823 26.0111L21.5868 28"
        stroke={process.env.PRIMARY_COLOR || "#5161BA"}
        stroke-width="2"
      />
      <path
        d="M4.25342 13.9993L8.57088 13.2798C10.3757 12.979 11.9405 14.5437 11.6397 16.3486L11.4603 17.4247C11.1392 19.3513 12.1122 21.2621 13.8592 22.1356V22.1356C15.2581 22.835 15.9835 24.4122 15.6042 25.9295L14.9201 28.666"
        stroke={process.env.PRIMARY_COLOR || "#5161BA"}
        stroke-width="2"
      />
      <circle
        cx="16.92"
        cy="15.9993"
        r="12.3333"
        stroke={process.env.PRIMARY_COLOR || "#5161BA"}
        stroke-width="2"
      />
    </svg>
  );
};

export default ZoneIcon;
