import React from "react";

export default function IconImg() {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1666 12H1.83337C0.822021 12 0 11.178 0 10.1666V1.83337C0 0.822021 0.822021 0 1.83337 0H14.1666C15.178 0 16 0.822021 16 1.83337V10.1666C16 11.178 15.178 12 14.1666 12ZM1.83337 1C1.37402 1 1 1.37402 1 1.83337V10.1666C1 10.626 1.37402 11 1.83337 11H14.1666C14.626 11 15 10.626 15 10.1666V1.83337C15 1.37402 14.626 1 14.1666 1H1.83337Z"
        fill="white"
      />
      <path
        d="M3.33337 4.66663C2.59802 4.66663 2 4.06873 2 3.33337C2 2.59802 2.59802 2 3.33337 2C4.06873 2 4.66663 2.59802 4.66663 3.33337C4.66663 4.06873 4.06873 4.66663 3.33337 4.66663ZM3.33337 3C3.14929 3 3 3.14929 3 3.33337C3 3.51733 3.14929 3.66663 3.33337 3.66663C3.51733 3.66663 3.66663 3.51733 3.66663 3.33337C3.66663 3.14929 3.51733 3 3.33337 3Z"
        fill="white"
      />
      <path
        d="M0.499878 10.667C0.371826 10.667 0.243896 10.6184 0.146484 10.5204C-0.0488281 10.3251 -0.0488281 10.0084 0.146484 9.81299L3.83984 6.11975C4.2959 5.6637 5.03784 5.6637 5.49316 6.11975L6.33325 6.95972L9.50659 3.78638C9.96252 3.33032 10.7046 3.33032 11.1599 3.78638L15.8533 8.47974C16.0486 8.67505 16.0486 8.9917 15.8533 9.18701C15.6578 9.38232 15.3412 9.38232 15.1459 9.18701L10.4525 4.49365C10.3872 4.42834 10.2778 4.42834 10.2133 4.49365L6.68652 8.02039C6.49121 8.2157 6.17456 8.2157 5.97925 8.02039L4.78589 6.82703C4.70117 6.74231 4.63123 6.74231 4.54651 6.82703L0.853271 10.5204C0.755859 10.6184 0.62793 10.667 0.499878 10.667Z"
        fill="white"
      />
    </svg>
  );
}
