const SignOutIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12.5L1.41435 12.0315L1.03953 12.5L1.41435 12.9685L2 12.5ZM11 13.25C11.4142 13.25 11.75 12.9142 11.75 12.5C11.75 12.0858 11.4142 11.75 11 11.75V13.25ZM5.41435 7.03148L1.41435 12.0315L2.58565 12.9685L6.58565 7.96852L5.41435 7.03148ZM1.41435 12.9685L5.41435 17.9685L6.58565 17.0315L2.58565 12.0315L1.41435 12.9685ZM2 13.25H11V11.75H2V13.25Z"
        fill={process.env.PRIMARY_COLOR || "#121212"}
      />
      <path
        d="M10 8.63193V7.88851C10 6.27017 10 5.461 10.474 4.9015C10.9479 4.34201 11.7461 4.20899 13.3424 3.94293L15.0136 3.6644C18.2567 3.12388 19.8782 2.85363 20.9391 3.75232C22 4.65102 22 6.29493 22 9.58276V15.4172C22 18.7051 22 20.349 20.9391 21.2477C19.8782 22.1464 18.2567 21.8761 15.0136 21.3356L13.3424 21.0571C11.7461 20.791 10.9479 20.658 10.474 20.0985C10 19.539 10 18.7298 10 17.1115V16.566"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default SignOutIcon;
