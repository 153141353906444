const LanguageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <circle
        cx="12"
        cy="12.5"
        r="8"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
      <path
        d="M18.2075 7.28485C17.4747 7.96088 16.5544 8.50912 15.5106 8.89144C14.4668 9.27376 13.3244 9.481 12.163 9.49875C11.0016 9.51651 9.84894 9.34434 8.7852 8.99423C7.72146 8.64412 6.77215 8.12447 6.00325 7.47139"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
      <path
        d="M18.3222 17.8235C17.5826 17.108 16.6367 16.5274 15.5553 16.1251C14.474 15.7227 13.2853 15.5091 12.0783 15.5003C10.8713 15.4914 9.67723 15.6875 8.58561 16.0739C7.494 16.4603 6.53311 17.0269 5.77495 17.7314"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
      <path
        d="M12 4.5V20.5"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
      <path
        d="M20 12.5H4"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LanguageIcon;
