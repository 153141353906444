const ButtonMin = (props) => (
  <svg
    width={props.width || "58"}
    height={props.width || "58"}
    viewBox="0 0 58 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.125 29.4897H39.875"
      stroke={process.env.PRIMARY_COLOR || "#EF1B43"}
      strokeWidth="2.71875"
      strokeLinecap="round"
    />
    <rect
      x="0.90625"
      y="1.396"
      width="56.1875"
      height="56.1875"
      rx="28.0938"
      stroke={process.env.PRIMARY_COLOR || "#EF1B43"}
      strokeWidth="1.8125"
    />
  </svg>
);

export default ButtonMin;
