const WalletIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.00195"
        y="6.5"
        width="20"
        height="16"
        rx="5"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
      <path
        d="M19.002 7.00012V7.00012C19.002 4.67704 16.8698 2.9391 14.5944 3.40756L5.99369 5.17829C3.66964 5.65677 2.00195 7.70279 2.00195 10.0756L2.00195 13.5001"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
      <path
        d="M6.00098 18.0001H12.001"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.001 14.5002C15.001 13.1195 16.1203 12.0002 17.501 12.0002H22.001V17.0002H17.501C16.1203 17.0002 15.001 15.881 15.001 14.5002V14.5002Z"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
      />
      <path
        d="M17.5029 14.5001H17.7029"
        stroke={process.env.PRIMARY_COLOR || "#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WalletIcon;
