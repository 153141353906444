export default function ButtonPlus(props) {
  return (
    <svg
      width={props.width || "58"}
      height={props.width || "58"}
      viewBox="0 0 58 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.489746"
        width="58"
        height="58"
        rx="29"
        fill={props.disabled ? "#929292" : process.env.PRIMARY_COLOR}
      />
      <path
        d="M29 18.6147L29 40.3647"
        stroke="white"
        strokeWidth="2.71875"
        strokeLinecap="round"
      />
      <path
        d="M39.875 29.4897L18.125 29.4897"
        stroke="white"
        strokeWidth="2.71875"
        strokeLinecap="round"
      />
    </svg>
  );
}
