const UserIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "33"}
      height={props.width || "32"}
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M26.5032 27.2625C25.8955 25.5614 24.5563 24.0583 22.6935 22.9862C20.8306 21.9141 18.5481 21.333 16.2 21.333C13.8519 21.333 11.5694 21.9141 9.70654 22.9862C7.84367 24.0583 6.50452 25.5614 5.89679 27.2625"
        stroke={process.env.PRIMARY_COLOR || "#5161BA"}
        stroke-width="2"
        stroke-linecap="round"
      />
      <circle
        cx="16.2"
        cy="10.6663"
        r="5.33333"
        stroke={process.env.PRIMARY_COLOR || "#5161BA"}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default UserIcon;
