import React from "react";

export default function PeopleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M19.6515 19.8951C20.2043 19.7799 20.5336 19.2014 20.2589 18.7081C19.6533 17.6204 18.6993 16.6646 17.4788 15.9363C15.907 14.9982 13.9812 14.4897 12 14.4897C10.0188 14.4897 8.09292 14.9982 6.52112 15.9363C5.30069 16.6646 4.34666 17.6204 3.74108 18.7081C3.46638 19.2014 3.79562 19.7799 4.34843 19.8951C9.39524 20.9469 14.6047 20.9469 19.6515 19.8951Z"
        fill="#121212"
      />
      <circle cx="12" cy="8.48975" r="5" fill="#121212" />
    </svg>
  );
}
