const CheckinIcon = (props) => {
  return (
    <svg
      width={props.width || "33"}
      height={props.width || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0998 5.1663C11.0998 4.79811 10.8013 4.49963 10.4331 4.49963C10.0649 4.49963 9.76648 4.79811 9.76648 5.1663V8.49963H7.09982C5.62706 8.49963 4.43315 9.69354 4.43315 11.1663V13.1663V13.833V18.4996C4.43315 18.8678 4.73162 19.1663 5.09982 19.1663C5.468 19.1663 5.76648 18.8678 5.76648 18.4996V13.833H27.0999V25.1663C27.0999 26.2708 26.2044 27.1663 25.0999 27.1663H14.4332C14.0651 27.1663 13.7665 27.4648 13.7665 27.833C13.7665 28.2011 14.0651 28.4996 14.4332 28.4996H25.0999C26.9408 28.4996 28.4332 27.0072 28.4332 25.1663V13.833V12.4996V11.1663C28.4332 9.69354 27.2392 8.49963 25.7665 8.49963H21.7665V5.1663C21.7665 4.79811 21.468 4.49963 21.0999 4.49963C20.7316 4.49963 20.4332 4.79811 20.4332 5.1663V8.49963H11.0998V5.1663ZM11.0998 16.4996C10.3634 16.4996 9.76648 17.0966 9.76648 17.833C9.76648 18.5694 10.3634 19.1663 11.0998 19.1663H13.7665C14.5029 19.1663 15.0999 18.5694 15.0999 17.833C15.0999 17.0966 14.5029 16.4996 13.7665 16.4996H11.0998ZM17.7665 17.833C17.7665 17.0966 18.3635 16.4996 19.0999 16.4996H21.7665C22.5029 16.4996 23.0999 17.0966 23.0999 17.833C23.0999 18.5694 22.5029 19.1663 21.7665 19.1663H19.0999C18.3635 19.1663 17.7665 18.5694 17.7665 17.833ZM13.7665 21.833H11.0998L13.7665 24.4996C14.5029 24.4996 15.0999 23.9027 15.0999 23.1663C15.0999 22.4299 14.5029 21.833 13.7665 21.833ZM17.7665 23.1663C17.7665 22.4299 18.3635 21.833 19.0999 21.833H21.7665C22.5029 21.833 23.0999 22.4299 23.0999 23.1663C23.0999 23.9027 22.5029 24.4996 21.7665 24.4996H19.0999C18.3635 24.4996 17.7665 23.9027 17.7665 23.1663ZM10.8829 25.0758C11.0391 24.9196 11.0391 24.6663 10.8829 24.5102L8.33732 21.9646C8.18112 21.8083 7.92786 21.8083 7.77164 21.9646C7.61543 22.1207 7.61543 22.374 7.77164 22.5302L9.63439 24.393H4.80007C4.57916 24.393 4.40007 24.572 4.40007 24.793C4.40007 25.0139 4.57916 25.193 4.80007 25.193H9.63439L7.77164 27.0558C7.61543 27.2119 7.61543 27.4652 7.77164 27.6214C7.92786 27.7776 8.18112 27.7776 8.33734 27.6214L10.8829 25.0758Z"
        fill={process.env.PRIMARY_COLOR || "#475291"}
      />
    </svg>
  );
};

export default CheckinIcon;
