const CheckoutIcon = (props) => {
  return (
    <svg
      width={props.width || "33"}
      height={props.width || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2999 5.1663C11.2999 4.79811 11.0014 4.49963 10.6332 4.49963C10.2651 4.49963 9.96657 4.79811 9.96657 5.1663V8.49963H7.29991C5.82715 8.49963 4.63324 9.69354 4.63324 11.1663V12.4996V13.833V25.1663C4.63324 27.0072 6.12563 28.4996 7.96657 28.4996H18.6331C19.0014 28.4996 19.2998 28.2011 19.2998 27.833C19.2998 27.4648 19.0014 27.1663 18.6331 27.1663H7.96657C6.86201 27.1663 5.96657 26.2708 5.96657 25.1663V13.833H27.2999V18.4996C27.2999 18.8678 27.5984 19.1663 27.9666 19.1663C28.3347 19.1663 28.6332 18.8678 28.6332 18.4996V13.833V13.1663V11.1663C28.6332 9.69354 27.4394 8.49963 25.9666 8.49963H21.9666V5.1663C21.9666 4.79811 21.668 4.49963 21.2999 4.49963C20.9318 4.49963 20.6332 4.79811 20.6332 5.1663V8.49963H11.2999V5.1663ZM11.2999 16.4996C10.5635 16.4996 9.96657 17.0966 9.96657 17.833C9.96657 18.5694 10.5635 19.1663 11.2999 19.1663H13.9666C14.703 19.1663 15.2999 18.5694 15.2999 17.833C15.2999 17.0966 14.703 16.4996 13.9666 16.4996H11.2999ZM17.9666 17.833C17.9666 17.0966 18.5635 16.4996 19.2999 16.4996H21.9666C22.703 16.4996 23.2999 17.0966 23.2999 17.833C23.2999 18.5694 22.703 19.1663 21.9666 19.1663H19.2999C18.5635 19.1663 17.9666 18.5694 17.9666 17.833ZM17.9666 23.1663C17.9666 22.4299 18.5635 21.833 19.2999 21.833H21.9666L19.2999 24.4996C18.5635 24.4996 17.9666 23.9027 17.9666 23.1663ZM9.96657 23.1663C9.96657 22.4299 10.5635 21.833 11.2999 21.833H13.9666C14.703 21.833 15.2999 22.4299 15.2999 23.1663C15.2999 23.9027 14.703 24.4996 13.9666 24.4996H11.2999C10.5635 24.4996 9.96657 23.9027 9.96657 23.1663ZM28.4163 25.0758C28.5724 24.9195 28.5724 24.6663 28.4163 24.51L25.8707 21.9644C25.7144 21.8083 25.4612 21.8083 25.305 21.9644C25.1488 22.1207 25.1488 22.374 25.305 22.5302L27.1678 24.393H22.3334C22.1126 24.393 21.9334 24.572 21.9334 24.793C21.9334 25.0139 22.1126 25.193 22.3334 25.193H27.1678L25.305 27.0556C25.1488 27.2119 25.1488 27.4651 25.305 27.6214C25.4612 27.7775 25.7144 27.7775 25.8707 27.6214L28.4163 25.0758Z"
        fill={process.env.PRIMARY_COLOR || "#475291"}
      />
    </svg>
  );
};

export default CheckoutIcon;
